import React, { useState } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { useForm } from "react-hook-form"
import PropTypes from "prop-types"
import Button from "../UI/button"
import Heading from "../../components/Heading"
import Section, { Container, Row, Col } from "../../components/UI/wrapper"
import Form, { FormGroup, Input, Error } from "../../components/UI/form"
import { LeftBox } from "./style"

const Newsletter = ({ sectionStyle, heading }) => {
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
  })
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  })
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    })
  }
  const onSubmit = (data, e) => {
    console.log(data)
    const form = e.target
    setServerState({ submitting: true })
    e.preventDefault()
    addToMailchimp(data.email)
      .then(r => {
        handleServerResponse(true, "Gracias!", form)
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form)
      })
  }
  return (
    <Section {...sectionStyle}>
      <Container>
        <Row className="align-items-center text-lg-left text-center">
          <Col xl={8} lg={5}>
            <LeftBox>
              <Heading {...heading} content="Recibe siempre nuestras ofertas" />
            </LeftBox>
          </Col>
          <Col xl={4} lg={7}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FormGroup mb="20px">
                <Input
                  name="email"
                  id="appointment_email"
                  type="email"
                  placeholder="Escribe tu Email aquí"
                  hover="2"
                  ref={register({
                    required: "Email es requerido",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Email inválido",
                    },
                  })}
                />
                <Error>{errors.email && errors.email.message}</Error>
              </FormGroup>
              <FormGroup textalign="center">
                <Button
                  type="submit"
                  pl="54px"
                  pr="54px"
                  disabled={serverState.submitting}
                  hover="2"
                >
                  Subscribirse
                </Button>
                {serverState.status && (
                  <p
                    className={`form-output ${
                      !serverState.status.ok ? "errorMsg" : "success"
                    }`}
                  >
                    {serverState.status.msg}
                  </p>
                )}
              </FormGroup>
            </Form>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}
Newsletter.propTypes = {
  heading: PropTypes.object,
}

Newsletter.defaultProps = {
  sectionStyle: {
    bgColor: "#ffc000",
    bgposition: "top 35% right -68px",
    bgsize: "auto",
    pt: "80px",
    pb: "80px",
    responsive: {
      medium: {
        pt: "60px",
        pb: "60px",
      },
      small: {
        pt: "40px",
        pb: "40px",
      },
    },
  },
  heading: {
    as: "h3",
    color: "white",
    child: {
      color: "primary",
    },
    responsive: {
      medium: {
        mb: "10px",
      },
    },
  },
  text: {
    as: "span",
    color: "secondary",
  },
  ButtonOne: {
    skin: "primary",
    m: "7px",
  },
  ButtonTwo: {
    skin: "secondary",
    m: "7px",
  },
}

export default Newsletter
